.hold {
    background-image: linear-gradient(rgba(58, 58, 58, 0.167),
            rgb(21, 21, 21)),
        url("./asset/extra.png");

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 46rem;
    font-family: 'Dancing Script', cursive;
}

.txthold {
    margin-top: 13rem;
}

.head1 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;
}

.head2 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;
}

.rocket {
    width: 32rem;
    margin: auto;
    display: block;
}