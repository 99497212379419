.hold {
    font-family: 'Poppins', sans-serif;
}

.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
}

.headcol {
    color: #cb0044;
}

.divHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.sticker {
    width: 28rem;
}

.txt {
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 0.9rem;

}

.txt2 {
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 0.9rem;

}

.progHead {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.card {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 0.3rem;
    padding-top: 1rem;
}

.image {
    display: block;
    margin: auto;
    width: 4rem;
}

.txt {
    text-align: center;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .head {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
    }

    .hold {
        font-family: 'Poppins', sans-serif;
        width: 55rem;
    }

    .divHold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }

    .sticker {
        width: 40rem;
        margin: auto;
        display: block;
    }

    .txt {
        text-align: center;
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 1.4rem;

    }

    .txt2 {
        text-align: center;
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 1.4rem;

    }

    .progHead {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
    }

    .cardHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .card {
        border: 1px solid rgb(223, 223, 223);
        border-radius: 0.3rem;
        padding-top: 1rem;
    }

    .image {
        display: block;
        margin: auto;
        width: 6rem;
    }

}