.hold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
}

.head {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
}

.col {
    color: #cb0044;
}

.left {
    width: 40%;
}

.image {
    width: 31rem;
    height: 30rem;
    margin-top: 3rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 4rem;
        margin-top: 2rem;
        width: 55rem;
    }

    .left {
        width: 100%;
    }

    .image {
        width: 35rem;
        height: 30rem;
        margin: auto;
        display: block;
    }

    .right {
        width: 100%;
    }
}