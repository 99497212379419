.hold {

    overflow: hidden;
}

.head {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.headcol {
    color: #cb0044;
}

.headcol2 {
    color: #eded02;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        width: 55rem;
        overflow: hidden;
    }

    .head {
        font-size: 3rem;

    }
}