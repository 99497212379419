.card {
    font-family: 'Poppins', sans-serif;
    color: white;
    width: 21rem;
}

.headHold {
    display: flex;
    justify-content: center;
    gap: 1rem;

}

.icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 1.5rem;
}

.head {
    font-size: 1.2rem;
    font-weight: 500;

}

.txt {
    width: 15rem;
    margin: auto;
    text-align: center;
}