.hold {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5rem;

}

.headHold {
    background-image: url("../../pages/asset/alphabet.jpeg");
    padding-top: 8rem;
    padding-bottom: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

}

.head {
    text-align: center;
    color: #cb0044;
    font-size: 3rem;
    font-weight: bold;
    background-color: aliceblue;
    border-radius: 0.4rem;
}

.head1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
}

.head1color {
    color: #cb0044;
}

.head2 {
    text-align: center;
    margin-top: -2rem;
    margin-left: 6rem;
    margin-right: 6rem;
}

.imgHold {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
}

.card1 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/dance.webp");
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card2 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/music.jpg");

    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card3 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/drawing.jpg");
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card4 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/yoga.jpg");
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card5 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/singing.jpg");
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card6 {
    background-image: linear-gradient(rgba(30, 30, 30, 0),
            rgba(39, 39, 39, 0.952)),
        url("./asset/handwriting.jpg");
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card7 {
    background-image: linear-gradient(rgba(0, 0, 0, 0),
            rgb(0, 0, 0)),
        url("./asset/abacus.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}

.card8 {
    background-image: linear-gradient(rgba(91, 91, 91, 0),
            rgba(0, 0, 0, 0.952)),
        url("./asset/vedic2.jpeg");

    width: 21rem;
    height: 20rem;
    border-radius: 1rem;
    position: relative;
    text-align: left;
    z-index: 1;
}


.cardTxt {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    color: white;
    font-size: 1.5rem;
}