.card {
    font-family: 'Poppins', sans-serif;
    background-color: rgb(245, 245, 245);
    padding-top: 3rem;
    padding-bottom: 1rem;

}

.image {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    margin: auto;
    display: block;
}

.txt {
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 1.1rem;
}

.name {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}