.hold {
    background-color: rgb(115, 191, 0);
    font-family: 'Poppins', sans-serif;
    padding-bottom: 1rem;
}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.head {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
}

.headcolor {
    color: #cb0044;
}

.txt {
    text-align: center;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        background-color: rgb(115, 191, 0);
        font-family: 'Poppins', sans-serif;
        padding-bottom: 1rem;
        width: 56rem;
    }

    .head {
        font-size: 3rem;
        font-weight: 600;
        text-align: center;
    }
}