.hold {
    background-image: linear-gradient(rgba(20, 20, 20, 0.171),
            rgb(21, 21, 21)),
        url("./asset/children.png");

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 46rem;
    font-family: 'Dancing Script', cursive;
}

.txthold {
    margin-top: 13rem;
}

.head1 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;


}

.head2 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;

}