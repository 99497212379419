.hold {
    margin-top: 5rem;
    font-family: 'Poppins', sans-serif;
    background-color: #cb0044;
    padding-top: 0.1rem;
}

.head {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    color: white;
}

.head2 {
    color: white;
    text-align: center;
    margin-top: -2.5rem;
}

.image {
    width: 11rem;
    height: 11rem;
    border-radius: 1rem;
}

.picContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
}

.picHold {}

.name {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        margin-top: 5rem;
        font-family: 'Poppins', sans-serif;
        background-color: #cb0044;
        padding-top: 0.1rem;
        width: 56rem;
    }

    .head {
        font-size: 3rem;

    }

    .picContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3rem;
    }

    .image {
        width: 18rem;
        height: 18rem;
        border-radius: 1rem;
    }

    .name {
        font-family: 'Poppins', sans-serif;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
    }
}