.hold {
    font-family: 'Poppins', sans-serif;
}

.head {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
}

.head1 {
    text-align: center;
    margin-top: -3rem;
    color: #cb0044;
    font-size: 1.1rem;
}

.divHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
}

.left {
    margin-top: 4rem;

}

.in {
    width: 21rem;
    height: 2.2rem;
    margin-bottom: 2.5rem;
}

.btn {
    margin: auto;
    display: block;
    border: none;
    border-radius: 0.5rem;
    background-color: yellow;
    width: 12rem;
    height: 3rem;
    font-size: 1.3rem;
    font-weight: bold;
}

.btn:hover {
    background-color: #cb0044;
    color: white;
}

.owl {}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        font-family: 'Poppins', sans-serif;
        width: 56rem;
    }

    .head1 {

        font-size: 1.4rem;
    }

    .divHold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }

    .left {
        margin-top: 4rem;
        margin: auto;
        display: block;

    }

    .owl {
        margin: auto;
        display: block;
    }

}