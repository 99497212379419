@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');



.hold {
    background-image: linear-gradient(rgba(183, 183, 183, 0.171),
            rgba(21, 21, 21)),
        url("./asset/abc.png");

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 46rem;
    font-family: 'Dancing Script', cursive;
}

.txthold {
    margin-top: 13rem;
}

.head1 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;

}

.head2 {
    color: white;
    text-align: center;
    font-size: 6rem;
    font-weight: 600;
}