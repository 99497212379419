.hold {
    width: 37rem;

    margin-top: -2rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        width: 55rem;

        margin-top: -2rem;
    }
}