.hold {
    font-family: 'Poppins', sans-serif;
}

.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
}

.headcolor {
    color: #cb0044;
}

.featureHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.feature {
    border: 1px solid rgb(219, 219, 219);
    padding-top: 1rem;
    width: 15rem;

}

.icon {
    width: 4rem;
    height: 4rem;
    margin: auto;
    display: block;
}

.txt {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width:768px) {

    .html {
        font-size: 45%;
    }

    .hold {
        font-family: 'Poppins', sans-serif;
        width: 55rem;
    }

    .featureHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .feature {
        border: 1px solid rgb(219, 219, 219);
        padding-top: 1rem;
        width: 25rem;

    }

    .icon {
        width: 6rem;
        height: 6rem;
        margin: auto;
        display: block;
    }

    .txt {
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.5rem;
    }

}