.hold {
    font-family: 'Poppins', sans-serif;
    background-color: rgb(244, 244, 244);
}

.abt {
    text-align: center;
    color: #cb0044;
}

.head1 {
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 2.5rem;
    font-weight: 500;
}

.head2 {
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 2.5rem;
    font-weight: 500;
    margin-top: -3rem;
}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;

}

.card {
    background-color: white;
    border-radius: 1rem;
    width: 30%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.cardImg {
    width: 4rem;
    margin: auto;
    display: block;
}

.cardHd {
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 1.7rem;
    font-weight: bold;
}

.cardtxt {
    text-align: center;
    color: rgb(108, 108, 108);
    font-size: 1.1rem;
    margin-left: 2rem;
    margin-right: 2rem;

}

.expHold {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.expHd {
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 2.5rem;
    font-weight: 500;
}

.expHd2 {
    text-align: center;
    color: rgb(120, 120, 120);
    margin-left: 7rem;
    margin-right: 7rem;
    margin-top: -2rem;
}

.programCardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    color: white;
}

.programCard1 {
    padding-top: 1rem;
    background-color: #cb0044;
    border-radius: 0.4rem;
}

.programCard2 {
    padding-top: 1rem;
    background-color: #dee602;
    border-radius: 0.4rem;
}

.programCard3 {
    padding-top: 1rem;
    background-color: #1eae01;
    border-radius: 0.4rem;
}

.programCard4 {
    padding-top: 1rem;
    background-color: #0087cb;
    border-radius: 0.4rem;
}

.programCard5 {
    padding-top: 1rem;
    background-color: rgb(215, 0, 0);
    border-radius: 0.4rem;
}

.cardHead {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: white;
}

.cardTxt {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    color: white;
}

.imgHold {
    background-image: linear-gradient(rgba(0, 0, 0, 0),
            rgba(29, 29, 29, 0.568)),
        url("./asset/cooking.jpg");

    background-attachment: fixed;
    width: 100%;
    height: 35rem;
    background-size: cover;
    color: white;
    position: relative;
    margin-bottom: 5rem;
}

.imgHoldIn {
    margin: 0;
    position: absolute;
    top: 30%;
}

.imgHold1 {
    margin-left: 4rem;
}

.imgHold2 {
    margin-left: 4rem;
    font-size: 4rem;
    font-weight: 500;
    margin-top: -1rem;
}

.imgHold3 {
    margin-left: 4rem;
    margin-top: -2rem;
}

.imgHold4 {
    margin-left: 4rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        font-family: 'Poppins', sans-serif;
        background-color: rgb(244, 244, 244);
        width: 56rem;
    }

    .programCardHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        color: white;
    }

    .programCard1 {
        padding-top: 1rem;
        background-color: #cb0044;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard2 {
        padding-top: 1rem;
        background-color: #dee602;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard3 {
        padding-top: 1rem;
        background-color: #1eae01;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard4 {
        padding-top: 1rem;
        background-color: #0087cb;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard5 {
        padding-top: 1rem;
        background-color: rgb(215, 0, 0);
        border-radius: 1rem;
        width: 50rem;
    }

    .cardImg {
        width: 11rem;
        margin: auto;
        display: block;
    }

    .cardHead {
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
        color: white;
    }

    .cardTxt {
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        color: white;
        font-size: 1.5rem;
    }

    .expHd2 {
        font-size: 1.5rem;
    }

}