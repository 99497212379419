.mainhold {
    margin-top: -12.5rem;
}

.hold {
    overflow: hidden;

}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .mainhold {
        margin-top: -12rem;
        width: 56.3rem;
    }
}