.hold {
    background-color: rgb(245, 245, 195);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.h1 {
    color: #cb0044;
}

.icon {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}

.hold2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
}

.h1In {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.head {
    font-size: 1.7rem;
    margin-top: -0.1rem;
}

.txt {
    text-align: center;
    color: green;
    font-size: 0.94rem;
    font-weight: 500;
}

li {
    list-style: none;
}

.palmHold {
    display: flex;
    justify-content: center;
    gap: 11rem;
}

.palm {
    width: 11rem;
    height: 11rem;
}

.icon2 {
    width: 3rem;
    margin-right: 0.8rem;
}

.company {
    text-align: center;
    font-size: 0.9rem;
    color: rgb(104, 104, 104);
}

.hrt {
    width: 1rem;
    margin-left: 0.2rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        background-color: rgb(245, 245, 195);
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 56rem;
    }

    .hold2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
    }

    .head {
        font-size: 3rem;
        margin-top: -0.1rem;
    }

    .txt {
        text-align: center;
        color: green;
        font-size: 2rem;
        font-weight: 500;
    }

    .icon2 {
        width: 4rem;
        margin-right: 1.8rem;
    }
}