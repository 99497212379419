.head {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    color: #cb0044;
}

.head2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: -3rem;
    text-align: center;
    color: #cb0044;
}

.imghold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.icon {
    width: 35rem;
    margin: auto;
    display: block;
}