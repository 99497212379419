@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.hold {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.icon {
    margin: auto;
    display: block;
    width: 7rem;
}

.card {
    background-color: #cb0044;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 25rem;
    border-radius: 1rem;
}

.card1 {
    background-color: green;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 25rem;
    border-radius: 1rem;
}

.card2 {
    background-color: rgb(147, 192, 0);
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 25rem;
    border-radius: 1rem;
}

.card4 {
    background-color: rgb(0, 110, 255);
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 25rem;
    border-radius: 1rem;
}

.head {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
}

.txt {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-left: 2rem;
    margin-right: 2rem;
}



.btn {
    display: block;
    margin: auto;
    width: 8rem;
    height: 2.2rem;
    border: none;
    border-radius: 0.4rem;
    background-color: black;
    color: white;

}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        width: 55rem;
    }

    .card {

        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 50rem;

    }

    .card1 {

        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 50rem;

    }

    .card2 {

        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 50rem;

    }

    .card4 {

        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 50rem;

    }

    .icon {
        margin: auto;
        display: block;
        width: 15rem;
    }

    .head {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 3rem;
        font-weight: 500;
        color: white;
    }

    .txt {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: white;
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 1.8rem;
    }

    .btn {
        display: block;
        margin: auto;
        width: 15rem;
        height: 4rem;
        border: none;
        border-radius: 0.4rem;
        background-color: black;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;

    }
}