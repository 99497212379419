@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.navHold {
    display: flex;
    flex-direction: column;
    margin-top: 5.2rem;
}

.navbarItems {
    display: flex;
    justify-content: space-around;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 60px;
    padding-top: 0.5rem;
    /*background-color: rgb(225, 223, 223);*/
    background-color: rgb(243, 243, 243);
    /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);*/
    width: 100%;
    height: 80px;
    /*border-radius: 13px;*/
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;

}

.list {
    color: black;
    list-style: none;
    text-decoration: none;
}

.list:hover {
    transform: scale(1.2);
    transition: 0.5s;
    color: white;
    /* background-color: rgb(3, 88, 224);*/
    border-radius: 5px;
    list-style: none;
}

.logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
}

.comlogo2 {
    width: 11rem;
    height: 8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 40px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
    margin-top: 0.7rem;
}

.nav-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem, 1rem;
    white-space: nowrap;
}

.nav-links:hover {
    background-color: rgb(80, 80, 238);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.fa-bars,
.fa-times {
    /*color: #fff;*/
    color: black;


}

.menu-icons {
    display: none;
}

.nav-links-mobile {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background-color: white;
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
}

.dropdown {
    position: relative;
    display: inline-block;
    /*color: #f1f1f1;*/
    color: black;
}

.dropdownContent {
    margin-top: 1rem;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    min-width: 21rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    border-radius: 0.5rem;
    font-size: 0.9rem;
    text-align: left;

}

.dropdown-content a:hover {
    background-color: black;
    color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.linker {
    text-decoration: none;
}

a {
    color: black;
}


@media screen and (max-width:850px) {
    .navbarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255, 255, 255, .25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        border-radius: 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .nav-menu.active {
        left: 0%;
    }

    .nav-links {
        display: block;
        width: 100%;
        padding: 2rem 0;
        color: #222;
        background-color: rgba(255, 255, 255, .25);
    }

    .nav-links:hover {

        background: rgba(255, 255, 255, .25);
        backdrop-filter: blur(20px);
    }

    .menu-icons {
        display: block;
    }

    .nav-links-mobile {
        padding: 1rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }


}