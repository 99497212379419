.hold {
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-family: 'Poppins', sans-serif;
}

.head {
    font-size: 2.5rem;
    font-weight: 500;
}

.headcolor {
    color: #cb0044;
}

.left {
    width: 40%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.845),
            rgba(255, 255, 255, 0.863)),
        url("../../Footer/asset/palm.png");
    background-size: 100%;
}

.right {
    width: 40%;
}

.cld {
    border-radius: 1rem;
    margin-top: 3rem;
    width: 36rem;

}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        font-family: 'Poppins', sans-serif;
        width: 55rem;
    }

    .left {
        width: 100%;
        background-image: linear-gradient(rgba(255, 255, 255, 0.845),
                rgba(255, 255, 255, 0.863)),
            url("../../Footer/asset/palm.png");
        background-size: 100%;
    }

    .right {
        width: 100%;
    }

    .cld {
        border-radius: 1rem;
        margin: auto;
        display: block;
        width: 50rem;

    }

    .head {
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
    }

    .txt {
        text-align: center;
        font-size: 1.5rem;
    }


}