.hold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(0, 208, 255);
    font-family: 'Poppins', sans-serif;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.txt1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: rgb(49, 49, 49);
}

.txt2 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgb(52, 52, 52);
}

.btnHold {
    margin-top: 5rem;

}

.btn {
    background-color: #cb0044;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin: auto;
    display: block;
    border: none;
    width: 13rem;
    height: 4rem;
    border-radius: 0.4rem;
}

.btn:hover {
    background-color: #ff0558;
    cursor: pointer;

}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgb(0, 208, 255);
        font-family: 'Poppins', sans-serif;
        margin-top: 5rem;
        margin-bottom: 5rem;
        width: 56rem;
        padding-bottom: 2rem;
    }

    .bird {
        margin: auto;
        display: block;
    }
}