/*background-image: linear-gradient(rgba(2, 80, 132, 0.8),
rgba(0, 14, 23, 0.8)),
url("./asset/playgroup.webp");*/

.hold {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5rem;

}

.headHold {
    background-image: url("./asset/senior.jpg");
    padding-top: 9rem;
    padding-bottom: 9rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100%;

}

.head {
    text-align: center;
    color: #cb0044;
    font-size: 3rem;
    font-weight: bold;
    background-color: aliceblue;
    border-radius: 0.4rem;
}

.divhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;
}

.head1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-top: -1rem;
}

.headcolor {
    color: #cb0044;
}

.play1 {
    width: 31rem;
    height: 26rem;
    border-radius: 0.2rem;
    margin-left: 1rem;
}

.txt1 {
    text-align: left;
    margin-right: 2rem;
}

.btn {
    margin: auto;
    display: block;
    border: none;
    width: 11rem;
    height: 3rem;
    background-color: #cb0044;
    color: white;
    font-weight: bold;
    border-radius: 0.4rem;
    font-size: 1.2rem;
}

.btn:hover {
    background-color: #fc0f5e;
    cursor: pointer;
}

.feeHead {
    text-align: center;

    font-size: 2.5rem;
    font-weight: bold;
}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.card1 {
    padding-top: 1rem;
    background-color: #cb0044;
    border-radius: 0.5rem;
    width: 30%;
}

.card2 {
    padding-top: 1rem;
    background-color: #02cde3;
    border-radius: 0.5rem;
    width: 30%;
}

.card3 {
    padding-top: 1rem;
    background-color: #14cb00;
    border-radius: 0.5rem;
    width: 30%;
}

.cardImg {
    width: 7rem;
    display: block;
    margin: auto;
}

.cardHead {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: white;
}

.cardTxt {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    color: white;
}

.aptitudeHead {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
}

.aptitudeTxt {
    text-align: center;
}

.txtHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.aptitudeLeft {
    width: 45%;
}

.aptitudeRight {
    width: 45%;
}


.list {
    display: list-item;
    list-style-type: square;
    margin-bottom: 1rem;
}

.programCardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;

}

.programCard1 {
    padding-top: 1rem;
    background-color: #cb0044;
    border-radius: 0.4rem;
}

.programCard2 {
    padding-top: 1rem;
    background-color: #dee602;
    border-radius: 0.4rem;
}

.programCard3 {
    padding-top: 1rem;
    background-color: #1eae01;
    border-radius: 0.4rem;
}

.programCard4 {
    padding-top: 1rem;
    background-color: #0087cb;
    border-radius: 0.4rem;
}

.programCard5 {
    padding-top: 1rem;
    background-color: rgb(215, 0, 0);
    border-radius: 0.4rem;
}

.programHead {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        font-family: 'Poppins', sans-serif;
        margin-bottom: 5rem;
        width: 56rem;

    }

    .programCardHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;

    }

    .programCard1 {
        padding-top: 1rem;
        background-color: #cb0044;
        border-radius: 1rem;
        width: 50rem;

    }

    .programCard2 {
        padding-top: 1rem;
        background-color: #dee602;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard3 {
        padding-top: 1rem;
        background-color: #1eae01;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard4 {
        padding-top: 1rem;
        background-color: #0087cb;
        border-radius: 1rem;
        width: 50rem;
    }

    .programCard5 {
        padding-top: 1rem;
        background-color: rgb(215, 0, 0);
        border-radius: 1rem;
        width: 50rem;
    }

    .cardImg {
        width: 11rem;
        display: block;
        margin: auto;
    }

    .cardHead {
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
        color: white;
    }

    .cardTxt {
        font-size: 1.5rem;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        color: white;
    }
}